import { HTTP } from '../../../../services/axios-config'
import endpoints from '../../../../services/endpoints'
import TYPES from '../types'

export default {
    async getLoginUserInfo ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.USER_ENDPOINT + payload)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_LOGIN_USER, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getUserInfo ({ commit }, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.GET_LOGIN_USER)
            .then(res => {
                // console.log(res)
                if (res.status === 200) {
                    commit(TYPES.UPDATE_USER, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async updateUserInfo ({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.patch(endpoints.USER_ENDPOINT + `${payload.userID}`, payload)
            .then(res => {
                if (payload.companyID) {
                    dispatch('updateCompanyInfo', payload)
                    resolve(res)
                } else {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async updateCompanyInfo ({ _ }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.patch(endpoints.COMPANY_ENDPOINT + `/${payload.companyID}`, payload.company)
            .then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getWarehouse ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.company + '/' + endpoints.WAREHOUSE_ENDPOINT)
            .then(res => {
                // console.log(res.data)
                if (res.status === 200) {
                    commit(TYPES.UPDATE_WAREHOUSES, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async addWarehouse ({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(payload.company + '/' + endpoints.WAREHOUSE_ENDPOINT, payload)
            .then(res => {
                if (res.status === 200) {
                    return dispatch('getWarehouse')
                    .then(_ => resolve(res))
                    .catch(_ => resolve(res))
                }   
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async UpdateWarehouse ({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(payload.company + '/' + endpoints.WAREHOUSE_ENDPOINT + `/${payload.uuid}`, payload)
            .then(res => {
                if (res.status === 200) {
                    return dispatch('getWarehouse')
                    .then(_ => resolve(res))
                    .catch(_ => resolve(res))
                }   
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async deleteWarehose ({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(endpoints.WAREHOUSE_ENDPOINT + `/${payload.id}`)
            .then(res => {
                if (res.status === 204) {
                    return dispatch('getWarehouse')
                    .then(_ => resolve(res))
                    .catch(_ => resolve(res))
                }   
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getWebshop ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const pageUrl = payload.page || payload.company + `/${endpoints.SUBSCRIPTION_ENDPOINTS}`
            HTTP.get(pageUrl)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_WEBSHOP, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getSingleWebshop ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const pageUrl = payload.company + `/${endpoints.SUBSCRIPTION_ENDPOINTS}/${payload.uuid}`
            HTTP.get(pageUrl)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_SINGLE_WEBSHOP, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async addWebshop ({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.SUBSCRIPTION_ENDPOINTS, payload)
            .then(res => {
                // console.log(res)
                return dispatch('getWebshop')
                .then(_ => resolve(res))
                .catch(_ => resolve(res))
                // if (res.status === 200) {
                // }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getPriceList ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(`${payload.company}/` + endpoints.SUBSCRIPTION_ENDPOINTS + '/' + payload.uuid + endpoints.PRICE_LIST_ENDPOINT)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_PRICELIST, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getWebshopOrders ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            let pageUrl = ''
            if (payload.page) {
                pageUrl = payload.uuid + endpoints.WEBSHOP_ORDER_ENDPOINTS + `?page=${payload.page}&limit=${payload.limit}&ordering=${payload.ordering}`
            } else {
                pageUrl = payload.uuid + endpoints.WEBSHOP_ORDER_ENDPOINTS + `?limit=${payload.limit}&ordering=${payload.ordering}`
            }
            HTTP.get(pageUrl)
            .then(res => {
                // console.log('orders|;', res)
                if (res.status === 200) {
                    commit(TYPES.UPDATE_WEBSHOP_ORDERS, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getWebshopOrdersSearch ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.id + endpoints.WEBSHOP_ORDER_ENDPOINTS + payload.query)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_WEBSHOP_ORDERS, { data : res.data, search : true })
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async updateWebshopOrderStatus ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const URL = payload.mode ? payload.id + endpoints.WEBSHOP_ORDER_ENDPOINTS + `/${payload.order_id}?=update_supplier_order_status=1` : payload.id + endpoints.WEBSHOP_ORDER_ENDPOINTS + `/${payload.order_id}`
            HTTP.patch(URL, payload.body)
            .then(res => {
                if (res.status === 200) {
                    // commit(TYPES.UPDATE_WEBSHOP_ORDER_STATUS, payload)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getWebshopPopularItems ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const pageUrl = payload.uuid + `/products${endpoints.SUPPLIER_ENDPOINT}?limit=${payload.limit}`
            HTTP.get(pageUrl)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_POPULAR_ITEMS, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getWebshopProducts ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            let pageUrl = ''
            if (payload.page) {
                if (payload.search) {
                    pageUrl = payload.uuid + endpoints.WEBSHOP_PRODUCT + '/' + payload.pricelist + `?q=${payload.search}&page=${payload.page}&ordering=${payload.ordering}`
                } else {
                    pageUrl = payload.uuid + endpoints.WEBSHOP_PRODUCT + payload.pricelist + `?page=${payload.page}&limit=${payload.limit}&ordering=${payload.ordering}`
                }
            } else {
                pageUrl = payload.uuid + endpoints.WEBSHOP_PRODUCT + payload.pricelist + `?limit=${payload.limit}&ordering=${payload.ordering}`
            }
            HTTP.get(pageUrl)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_WEBSHOP_PRODUCTS, { data : res.data, search : payload.search })
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getWebshopProductSearch ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.id + endpoints.WEBSHOP_PRODUCT + payload.pricelist + payload.query)
            .then(res => {
                if (res.status === 200) {
                    // commit(TYPES.UPDATE_WEBSHOP_PRODUCTS_SEARCH, res.data)
                    commit(TYPES.UPDATE_WEBSHOP_PRODUCTS, { data : res.data, search : true })
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getProductFilterStatus ({ commit }, payload) {
        // console.log('log:', payload)
        return new Promise((resolve, reject) => {
            HTTP.get(payload.id + endpoints.STOCK_WEBSHOP_PRODUCT + payload.query)
            .then(res => {
                if (res.status === 200) {
                    // console.log('d:', res)
                    commit(TYPES.UPDATE_WEBSHOP_PRODUCTS, { data : res.data, search : true })
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async deleteWebshopProducts ({ _ }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(payload.id + `/${endpoints.PRODUCT_ENDPONTS}` + '/discontinue', payload.product)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async deleteWebshopBulkProductStock ({ _ }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(payload.subscriptionId + `${endpoints.PRODUCT_DISCONTINUE_SUPPLIER}/discontinue`, payload.product)
            .then(res => {
                resolve(res)
                // if (res.status === 204) {
                // }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async deleteWebshopSingleProductStock ({ _ }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(payload.subscriptionId + `${endpoints.PRODUCT_DISCONTINUE_SUPPLIER}/${payload.productUUID}/discontinue`)
            .then(res => {
                if (res.status === 204) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async remoteSearchProducts ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.uuid + endpoints.WEBSHOP_PRODUCT + payload.pricelist + `?q=${payload.value}&limit=${payload.limit}`)
            .then(res => {
                if (res.status === 200) {
                    // commit(TYPES.UPDATE_WEBSHOP_PRODUCTS_SEARCH, res.data)
                    commit(TYPES.UPDATE_WEBSHOP_PRODUCTS, { data : res.data, search : true })
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async updateWebshop ({ state, dispatch }, payload) {
        const resBody = []
        payload.row.forEach(element => {
            const obj = {}
            const ProductObject = state.webshopProducts.results[element]
            obj.sales_price = ProductObject.newSalesPrice
            obj.product_price_stock_id = ProductObject.uuid
            resBody.push(obj)
        })

        return new Promise((resolve, reject) => {
            HTTP.post(payload.uuid + endpoints.WEBSHOP_PRODUCT + payload.pricelist, resBody)
            .then(res => {
                if (res.status === 200) {
                    if (payload.remote) {
                        dispatch('syncRemotePrices', payload)
                        .then(_ => {
                            resolve(res)
                        })
                        .catch(err => {
                            reject(err)
                        })
                    } else {
                        resolve(res)
                    }
                }
            }).catch(err => reject(err))
        })
    },
    async syncRemotePrices ({ state }, payload) {
        const arrayIds = []
        payload.row.forEach(element => {
            const ProductObject = state.webshopProducts.results[element]
            arrayIds.push(ProductObject.uuid)
        })
        const data = {
            ids : arrayIds
        }
        return new Promise((resolve, reject) => {
            HTTP.post(payload.uuid + endpoints.REMOTE_PRICES, data)
            .then(res => {
                // console.log('From:remote:', res)
                resolve(res)
            })
            .catch(err => {
                // console.log('error:remote:', err.response)
                reject(err)
            })
        })
    },
    async getSuppliers ({ commit }, payload) {
        await HTTP.get(payload.uuid + endpoints.SUPPLIER_DETAILS)
        .then(res => commit(TYPES.UPDATE_SUPPLIERS, res.data))
        .catch(_ => {})
    },
    async getSupplierOrders ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            let pageUrl = ''
            if (payload.page) {
                pageUrl = payload.uuid + endpoints.SUPPLIER_ORDERS + `?page=${payload.page}&limit=${payload.limit}&ordering=${payload.ordering}`
            } else {
                pageUrl = payload.uuid + endpoints.SUPPLIER_ORDERS + `?limit=${payload.limit}&ordering=${payload.ordering}`
            }
            HTTP.get(pageUrl)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_SUPPLIER_ORDERS, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async updateSupplierOrderStatus ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const URL = payload.mode ? payload.id + endpoints.SUPPLIER_ORDERS + `/${payload.order_id}?=update_webshop_order_status=1` : payload.id + endpoints.SUPPLIER_ORDERS + `/${payload.order_id}`
            HTTP.patch(URL, payload.body)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_SUPPLIER_ORDER_STATUS, payload)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async remoteSearchOrders ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.id + endpoints.SUPPLIER_ORDERS + `${payload.query}`)
            .then(res => {
                // console.log('res:', res.data)
                if (res.status === 200) {
                    commit(TYPES.UPDATE_SUPPLIER_ORDERS, { data : res.data, search : true })
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getSupplierDetails ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            // HTTP.get(payload.uuid + endpoints.SUPPLIER_ORDERS)
            // .then(res => {
            //     console.log(res)
            //     if (res.status === 200) {
            //         commit(TYPES.UPDATE_SUPPLIER_ORDERS, res.data)
            //         resolve(res)
            //     }
            // }).catch((err) => {
            //     reject(err)
            // })
        })
    },
    async searchSuppliers ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.id + endpoints.WEBSHOP_PRODUCT + '/' + payload.pricelist + '?supplier=' + payload.name)
            .then(res => {
                if (res.status === 200) {
                    // commit(TYPES.SEARCH_RESULTS, res.data)
                    commit(TYPES.UPDATE_WEBSHOP_PRODUCTS, { data : res.data, search : true })
                    resolve('success')
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async uploadUserPhoto ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.FILE_UPLOAD_ENDPOINT, payload)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_USER_PHOTO, res.data)
                    resolve('success')
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async userInvite ({ _ }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.GET_LOGIN_USER + `/${payload.uuid}/` + endpoints.USER_INVITE, payload.payload)
            .then(res => {
                if (res.status === 200) {
                    resolve('success')
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async customDeleteRequest ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(payload.URL)
            .then(res => {
                resolve(res)
                // if (res.status === 204) {
                // }   
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async customGetRequest ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (payload.users) {
                    commit(TYPES.UPDATE_LINKED_USERS, res.data)
                }
                resolve(res)  
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async customPostRequest ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(payload.URL, payload)
            .then(res => {
                resolve(res) 
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async customPatchRequest ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.patch(payload.URL, payload)
            .then(res => {
                resolve(res) 
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async customPutRequest ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(payload.URL, payload)
            .then(res => {
                resolve(res) 
            }).catch((err) => {
                reject(err)
            })
        })
    },
}