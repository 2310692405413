import moment from 'moment'
import endpoints from './endpoints'
import helpers from './helpers'

// import 'moment/locale/en';
// register the Moment.js locale of your choice
moment.locale('en');

export default {
    install : (app, options) => {
        app.config.globalProperties.$services = {
            helpers,
            endpoints,
            moment
        }
    }
}
