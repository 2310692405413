const endpoints = {
    USER_ENDPOINT : 'users/',
    COMPANY_ENDPOINT : 'companies',
    AUTH_LOGIN : 'companies/login',
    AUTH_REGISTER : 'companies/register',
    FORGET_USER_PASSWORD : 'users/password/forget',
    RESET_USER_PASSWORD : 'users/password/reset',
    ACCEPT_INVITE_API : 'companies/users/accept_invite',
    FILE_UPLOAD_ENDPOINT : 'companies/image/upload',
    GET_LOGIN_USER : 'companies',
    USER_INVITE : 'users/invite_user',
    PRODUCT_ENDPONTS : 'products',
    WEBSHOP_ORDER_ENDPOINTS : '/webshop_orders',
    SUBSCRIPTION_ENDPOINTS : 'subscriptions',
    WAREHOUSE_ENDPOINT : 'warehouses',
    WEBSHOP_PRODUCT : '/products/webshops/',
    // WEBSHOP_PRODUCT : '/product_price_lists/webshop_products',
    STOCK_WEBSHOP_PRODUCT : '/product_price_stocks',
    SHOP_STATUS_MAPPING : '/shop_status_mappings',
    SHOP_STATUS_SUPPLIER : '/supplier_order_status',
    PRODUCT_DISCONTINUE_SUPPLIER : '/products/suppliers',
    REMOTE_PRICES : '/products/webshops/sync_webshop_prices',
    SUPPLIER_ORDERS : '/supplier_orders',
    SUPPLIER_ENDPOINT : '/suppliers',
    SUPPLIER_DETAILS : '/supplier_details',
    PRICE_LIST_ENDPOINT : '/available_price_lists',
    TRACKING_COMPANY : '/tracker_company_codes',
    ORDER_RULE_ENDPOINT : '/order_rules',
    TIMEZONE_ENDPOINT : '/utility/time_zones',
    OAUTH_ENDPOINT : 'oauth/'
}

export default endpoints
