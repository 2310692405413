const JITCONTOL_TOKEN = '__jit_services__'
const JITCONTOL_REFRESH = '__jit_dead__'
const JITCONTOL_USER_REF = '__ref_id__'
const JITCONTOL_CHOOSEN_COMPANY = '__company_ref__'
const FILTER_PRICE_RULE = '__filrule__'
const FILTER_SUPPLIER = '__supFilter__'
const FILTER_WEBSHOP = '__webFilter__'

export const saveToken = data => {
    if (data.access) {
        window.localStorage.setItem(JITCONTOL_TOKEN, data.access)
        window.localStorage.setItem(JITCONTOL_REFRESH, data.refresh)
    }
    if (data.id) {
        window.localStorage.setItem(JITCONTOL_USER_REF, data.id)
    }
}

export const saveChoosenCompany = (data) => {
    return window.localStorage.setItem(JITCONTOL_CHOOSEN_COMPANY, data)
}

export const getToken = () => {
    return window.localStorage.getItem(JITCONTOL_TOKEN)
}

export const getRefreshToken = () => {
    return window.localStorage.getItem(JITCONTOL_REFRESH)
}

export const getUserRef = () => {
    return window.localStorage.getItem(JITCONTOL_USER_REF)
}

export const getChoosenCompany = () => {
    return window.localStorage.getItem(JITCONTOL_CHOOSEN_COMPANY)
}

export const getRuleFilterStorage = () => {
    const result = window.localStorage.getItem(FILTER_PRICE_RULE)
    if (result) {
        return JSON.parse(result)
    }
    return []
}

export const getSupplierFilterStorage = () => {
    const result = window.localStorage.getItem(FILTER_SUPPLIER)
    if (result) {
        return JSON.parse(result)
    }
    return []
}

export const getWebshopFilterStorage = () => {
    const result = window.localStorage.getItem(FILTER_WEBSHOP)
    if (result) {
        return JSON.parse(result)
    }
    return []
}

export const storeRuleFilter = (data) => {
    window.localStorage.setItem(FILTER_PRICE_RULE, JSON.stringify(data))
}

export const storeSupplierFilter = data => {
    window.localStorage.setItem(FILTER_SUPPLIER, JSON.stringify(data))
}

export const storeWebshopFilter = (data) => {
    window.localStorage.setItem(FILTER_WEBSHOP, JSON.stringify(data))
}


export const destroyToken = () => {
    window.localStorage.removeItem(JITCONTOL_TOKEN)
    window.localStorage.removeItem(JITCONTOL_REFRESH)
    window.localStorage.removeItem(JITCONTOL_USER_REF)
    window.localStorage.removeItem(JITCONTOL_CHOOSEN_COMPANY)
}

export default { 
    saveToken, 
    getToken, 
    getRefreshToken, 
    getUserRef, 
    saveChoosenCompany, 
    getChoosenCompany, 
    destroyToken,
    getRuleFilterStorage,
    storeRuleFilter,
    getSupplierFilterStorage,
    getWebshopFilterStorage,
    storeSupplierFilter,
    storeWebshopFilter,
}
