import { createI18n } from 'vue-i18n'

const messages = {
    en : {
        hi : 'Hi,',
        welcomeBack : 'Welcome back',
        usernameOrEmail : 'Username / Email Address',
        CustomerAlready : 'Already a Customer',
        notCustomer : 'Don\'t you have an account',
        resettingPassword : 'Resetting',
        reset : 'Reset Password',
        repeatPassword : 'Repeat Password',
        newPassword : 'New Password',
        companyNumber : 'Company Number',
        fieldRequired : 'All fields are required',
        invalidOrganizationNumber : 'Invalid organization number',
        passwordGreater : 'Password should be greater than 6',
        passwordNotMatch : 'Password do not match',
        confirmNewPassword : 'Confirm New Password',
        loginError : 'Login credentials are invalid',
        upload : 'Upload Photo',
        send : 'Send',
        signUp : 'Sign Up',
        overview : 'Overview',
        dashboard : 'Dashboard',
        invitedSuccessful : 'Successfully invited a user',
        permissionDenied : 'You don\'t have access to perform this operation',
        report : 'Reports',
        webshop : 'Webshop',
        warehouse : 'Warehouse',
        management : 'Management',
        order : 'Order',
        product : 'Products',
        productOverview : 'Products Overview',
        setting : 'Settings',
        profile : 'Profile',
        users : 'Users',
        customer : 'Customer',
        productManager : 'Product Manager',
        priceRule : 'Price Rule',
        updatePriceRule : 'Update price rule settings',
        supplierOrderManager : 'Supplier Orders',
        supplierOrderOverview : 'Supplier Order Overview',
        supplier : 'Supplier',
        webshopOrderManager : 'Webshop Order Manager',
        webshopOrderOverview : 'Webshop Orders',
        metricsOverview : 'Metric Oversight',
        totalMonth : 'Total Orders this Month',
        averageMargin : 'Average Profit Margin',
        averagePerOrder : 'Average Profit Per Order',
        mostPopularItems : 'Most Popular Items',
        createOrder : 'Create Order',
        back : 'Back',
        showSupplier : 'Show Suppliers',
        process : 'Process',
        ref : 'Ref',
        location : 'Location',
        status : 'Status',
        expectedDate : 'Expected Date',
        dateCreation : 'Date Creation',
        dateModified : 'Date Modified',
        action : 'Action',
        filter : 'Filters',
        search : 'Search',
        suppliers : 'Suppliers',
        addSupplier : 'Add Supplier',
        ftpHost : 'FTP Host',
        ftpUser: 'FTP User',
        ftpPassword : 'FTP Password',
        ftpPort : 'FTP Port',
        ftpBuyerID : 'FTP Buyer ID',
        apiKey : 'API KEY',
        shippingPrice : 'Shipping Price',
        deliveryTime : 'Delivery Time',
        orderEmail : 'Order Email',
        postProduct : 'Post Product',
        syncOrderOnly : 'Sync Order Only',
        supplierOverview : 'Supplier Overview',
        addWarehouse : 'Add Warehouse',
        warehouseName : 'Warehouse Name',
        address : 'Address',
        city : 'City',
        email : 'Email',
        country : 'Country',
        phone : 'Phone',
        note : 'Note',
        zip : 'Zip Code',
        connect : 'Connect',
        connecting : 'Connecting',
        warehouseOverview : 'Warehouse Overview',
        webshopOverview : 'Webshop Overview',
        update : 'Update',
        addWebshop : 'Add Webshops',
        shopName : 'Shop Name',
        dropship : 'Dropship',
        shopURL : 'Shop URL',
        dropshipOrder : 'Dropship Orders',
        sendEmailTrackingAlert : 'Send Email Tracking Alerts',
        shopPhone : 'Shop Phone',
        shopEmail : 'Shop Email',
        apiStore : 'API Storename',
        clientSecret : 'Client Secret',
        clientID : 'Client ID',
        storelogo : 'Store Logo',
        emailServer : 'Email Server',
        emailSender : 'Email Sender',
        emailPassword : 'Email Password',
        emailPort : 'Email Port',
        emailCopy : 'Email CC',
        emailSubject : 'Email Subject',
        add : 'Add',
        generalSetting : 'General Settings',
        setGlobalMargin : 'Set Global Margin',
        round : 'Rounding',
        roundUp : 'Rounding up to nearest whole number',
        roundDown : 'Rounding down to the nearest whole number',
        saveSetting : 'Save Settings',
        calculateSelectItem : 'Calculate Selected',
        applyCalculateAll : 'Apply all Calculation',
        exportFile : 'Export File',
        show : 'Show',
        updateWebshop : 'Update Webshop',
        updating : 'Updating',
        save : 'Save',
        saving : 'Saving',
        defaultVat : 'Default Vat Rate',
        priceListSelection : 'Select Price List',
        disconnect : 'Disconnect',
        cropping : 'Cropping',
        crop : 'Crop',
        amount : 'Amount',
        margin : 'Margin',
        salesPrice : 'SalesPrice',
        date : 'Date',
        isEqualTo : 'is equal to',
        isGreaterThan : 'is greater than',
        isBetween : 'is between',
        isLessThan : 'is less than',
        received : 'Received',
        processing : 'Processing',
        yes : 'Yes',
        no : 'No',
        cancel : 'Cancel',
        clear : 'Clear',
        done : 'Done',
        orderRule : 'Order Rules',
        cheapestProductPrice : 'Cheap Product Price',
        fastestDeliveryTime : 'Fastest Delivery Time',
        cheapestDeliveryPrice : 'Cheapest Delivery Price',
        brandPreference : 'Brand Preferences',
        orderNumber : 'Order #',
        customerName : 'Customer Name',
        orderDate : 'Order Date',
        deliveryDate : 'Delivery Date',
        supplierOrder : 'Supplier Order',
        enter : 'Enter',
        webshopPricelist : 'Webshop PriceList',
        fetchingProduct : 'fetching products',
        exporting : 'Exporting',
        orderCompleted : 'Order Completed',
        orderProcessing : 'Order Processing',
        orderCancel : 'Order Cancelled',
        orderPending : 'Order Received',
        picking : 'Picking',
        inProgress : 'In Progress',
        partialFulfilled : 'Partially Fulfilled',
        failed : 'Failed',
        croppedSuccessfully : 'Cropped successfully',
        ascending : 'Ascending',
        descending : 'Descending',
        logout : 'Logout',
        username : 'Username',
        password : 'Password',
        forgetPassword : 'Forget Password',
        signIn : 'Sign In',
        switch : 'switch to',
        english : 'English',
        notify : 'Notifications',
        sortBy : 'Sort By',
        deliveryDateAsc : 'Delivery Date (ASC)',
        deliveryDateDsc : 'Delivery Date (DSC)',
        orderDateAsc : 'Order Date (ASC)',
        orderDateDesc : 'Order Date (DESC)',
        amountAsc : 'Amount (ASC)',
        amountDesc : 'Amount (DESC)',
        newHere : 'New Here',
        loginFrontText : 'Sign up and discover great opportunities using one of the world\'s great Product Inventory Management',
        undoSelection : 'Undo Selection',
        updateAccount : 'Update Account', 
        loginSuccessful : 'You\'ve login successfully',
        checkEmail : 'Please check your email for instructions',
        invalidEmail : 'Invalid Email Address',
        registeredSuccessful : 'You\'ve registered successfully',
        invalidUser : 'Invalid User',
        resetSuccessful : 'Password reset successfully. Please login',
        uploadUserPhoto : 'Upload User Photo',
        croppedPhoto : 'Cropped Photo',
        fileChosen : 'No file chosen',
        photoUploaded : 'User photo uploaded successfully',
        croppedSuccess : 'Cropped Successfully',
        removedWarehouse : 'Are you sure you want to remove warehouse',
        warning : 'Warning',
        deletedWarehouse : 'Successfully deleted warehouse',
        deletedProductSuccess : 'Product deleted successfully',
        deletedOrderSuccess : 'Product deleted successfully',
        updatedWarehouse : 'Warehouse updated successfully',
        updatedWebshop : 'Webshop updated successfully',
        updateSupplier : 'Update Supplier',
        updateWarehouse : 'Update Warehouse',
        currency : 'Currency',
        sending : 'Sending',
        creatingAccount : 'Creating Account',
        name : 'Name',
        costPrice : 'CostPrice',
        salesPriceInkVat : 'SalesPrice Inc Vat',
        salesPriceExVat : 'SalesPrice ex Vat',
        invalidCellValue : 'Invalid cell value',
        invalidMarginValue : 'Invalid margin value',
        fieldValue : 'Field value cannot be longer than 50 Characters',
        firstName : 'Firstname',
        lastName : 'Lastname',
        calculating : 'Calculating',
        adding : 'Adding',
        webshopOrderNumber : 'Webshop Order#',
        total : 'Total',
        resultFound : 'results found',
        vendorNumber : 'Vendor Number',
        available : 'Available',
        discontinued : 'Discontinued',
        massDelete : 'Mass Delete',
        discountedPrice : 'Discounted',
        quantity : 'Quantity',
        removeProducts : 'Are you sure you want to delete this product(s)',
        loading : 'Loading...',
        userManagement : 'User Management',
        role : 'Role',
        employee : 'Employee',
        inviteUser : 'Invite User',
        userOverview : 'User Overview',
        number : 'Number',
        shop : 'Shop',
        signin : 'Signing',
        invitationSent : 'Invitation sent successfully',
        accountSettings : 'Account Settings',
        account : 'Accounts',
        phoneNumber : 'Phone Number',
        companyName : 'Company Name',
        company : 'Company',
        companyVatId : 'Company Vat ID',
        billingAddress : 'Billing Address',
        zipCode : 'Zip Code',
        postalCode : 'Postal Code',
        shippingInfo : 'Shipping Information',
        companyEmail : 'Company Email',
        companyEmailNotice : 'Company Email for notices',
        userUpdated : 'User Information updated successfully',
        webshopStatusUpdated : 'Order status updated successfully',
        deleteOrder : 'Are you sure you want to delete this order',
        delete : 'delete',
        deleteWebshopOrder : 'Delete Webshop Order',
        deleteSupplierOrder : 'Delete Supplier Order',
        deleteSubscription : 'Delete Subscription',
        deleting : 'deleting',
        notAvailable : 'Not available',
        yesDelete : 'Yes delete',
        languages : 'Languages',
        webshopInformation : 'Webshop Information',
        viewDetails : 'View Details',
        editInfo : 'Edit Info',
        viewProfile : 'View Profile',
        success : 'Successfully',
        price : 'Price',
        profit : 'Profit Margin',
        inStock : 'In Stock',
        outStock : 'Out of stock',
        orderSettings : 'Order Settings',
        startTime : 'Start Time',
        stopTime : 'Stop Time',
        timezone : 'TimeZone',
        pleaseWait : 'Please wait',
        acceptingInvite : 'Accepting Invitation',
        invited : 'Invited',
        connected : 'Connected',
        continue : 'Continue',
        createSupplierOrder : 'Create Supplier Order',
        updateSupplierOrderStatus : 'Do you want to also update webshop order',
        selectEcommerce : 'Select eCommerce Platform',
        configureShopify : 'Configure Shopify',
        specificDropshipOrder : 'Specific Dropship order',
        deliveryType : 'Delivery Type',
        customerType : 'Customer Type',
        supplierHeading: 'If there is a match on same product with multiple suppliers, which order of priority would you like?',
        brandHeading : 'Is there a specific Brand which you want only to be ordered from a specific supplier when in stock?',
        saveMapping : 'Saved brand mappings',
        supplierSelection : 'Supplier Selection',
        selectProduct : 'Please select at least a product',
        unitPrice : 'Unit Price',
        totalPrice : 'Total Price',
        brand : 'Brand',
        person : 'Person',
        warehouseInfomation : 'Warehouse Information',
        deleteSupplier : 'Are you sure you want to delete this supplier',
        popularItems : 'Most Popular Items',
        revenue : 'Revenue',
        netProfit : 'Net Profit',
        selectPriceList : 'Select Price List',
        saleStatistics : 'Sale Statistics',
        topCountires : 'Top Countries',
        created : 'Created',
        notConnected : 'Not Connected',
        activeFilter : 'Active Filter',
        manufacturer : 'Manufacturer',
        skuAsc : 'SKU (ASC)',
        skuDsc : 'SKU (DSC)',
        vendorNumAsc : 'Vendor# ASC',
        vendorNumDsc : 'Vendor# DSC',
        vendorNameAsc : 'Vendor Name ASC',
        vendorNameDsc : 'Vendor Name DSC',
        productNameAsc : 'Product Name ASC',
        productNameDsc : 'Product Name DSC',
        costPriceAsc : 'CostPrice ASC',
        costPriceDsc : 'CostPrice DSC',
        salesPriceIncAsc : 'SalesPrice Inc ASC',
        salesPriceIncDsc : 'SalesPrice Inc DSC',
        salesPriceExAsc : 'SalesPrice Ex ASC',
        salesPriceExDsc : 'SalesPrice Ex DSC',
        marginAsc : 'Margin ASC',
        marginDsc : 'Margin DSC',
        notification : 'Notifications',
        emailSettings : 'Email Settings',
        addWebshopQuickbutik : 'Add Quickbutik',
        addShopifyWebshop : 'Add Webshop Shopify',
        topCustomers : 'Top Customers',
        topProductPerformance : 'Top Product Performance',
        totalSpent : 'Total Spent',
        totalGrossProfit : 'Total Gross Profit',
        totalSold : 'Total Sold',
        exportExcel : 'Export to Excel',
        supplierConnectors : 'Supplier Connectors',
        turnover : 'Turnover',
        grossProfit : 'Gross Profit',
        thisMonth : 'This Month',
        thisWeek : 'This Week',
        latestOrders : 'Latest Orders',
        pleaseWaitCalculation : 'Please wait while your product prices are being calculated',
    },
    se : {
        hi : 'Hej,',
        welcomeBack : 'Välkommen tillbaka',
        usernameOrEmail : 'Användarnamn / E-postadress',
        CustomerAlready : 'Redan en kund',
        notCustomer : 'Inte en kund ännu',
        newPassword : 'Nytt lösenord',
        repeatPassword : 'Repetera lösenord',
        companyNumber : 'Orgnummer',
        fieldRequired : 'Alla fält är obligatoriska',
        invalidOrganizationNumber : 'Ogiltigt org nummer',
        passwordGreater : 'Lösenordet bör vara större än 6',
        passwordNotMatch : 'Lösenorden matchar inte',
        confirmNewPassword : 'Bekräfta nytt lösenord',
        loginError : 'Ogiltigt lösenord',
        upload : 'Ladda upp foto',
        send : 'Skicka',
        reset : 'Återställ lösenord',
        resettingPassword : 'Återställ',
        signUp : 'Skapa konto',
        overview : 'Översikt',
        dashboard : 'Lobby',
        invitedSuccessful : 'Inbjudan skickad',
        permissionDenied : '',
        report : 'Rapporter',
        webshop : 'Webbutik',
        warehouse : 'Lagerställen',
        management : 'Butikshantering',
        order : 'Ordrar',
        product : 'Produkter',
        productOverview : 'Produkter Översikt',
        setting : 'Inställningar',
        profile : 'Profil',
        users : 'Användare',
        customer : 'Kunder',
        productManager : 'Produkthantering',
        priceRule : 'Prishantering',
        updatePriceRule : 'Vänligen sätt prisregel inställningar först',
        supplierOrderManager : 'Leverantörshantering',
        supplierOrderOverview : 'Leverantörsöversikt',
        supplier : 'Leverantörer',
        webshopOrderManager : 'Orderhantering',
        webshopOrderOverview : 'Orderöversikt',
        metricsOverview : 'Statistik',
        totalMonth : 'Totalt denna månad',
        averageMargin : 'Genomsnitt vinstmarginal',
        averagePerOrder : 'Genomsnitt vinst/order',
        mostPopularItems : 'Mest sålda artiklar',
        createOrder : 'Skapa order',
        back : 'Tillbaka',
        showSupplier : 'Leverantör',
        process : 'Hantera',
        ref : 'Nummer',
        location : 'Leveransadress',
        status : 'Status',
        expectedDate : 'Förväntat datum',
        dateCreation : 'Orderdatum',
        action : 'Actions',
        filter : 'Filter',
        search : 'Sök',
        suppliers : 'Leverantörer',
        addSupplier : 'Lägg till leverantör',
        ftpHost : 'FTP Host',
        ftpUser: 'FTP Användarnamn',
        ftpPassword : 'FTP Lösenord',
        ftpPort : 'FTP Port',
        apiKey : 'API nyckel',
        shippingPrice : 'Fraktkostnad',
        deliveryTime : 'Leveranstid',
        orderEmail : 'E-post för orderläggning',
        postProduct : 'Skapa produkter',
        syncOrderOnly : 'Synka ordrar enbart',
        supplierOverview : 'Leverantörsöversikt',
        warehouseName : 'Lagernamn',
        address : 'Adress',
        city : 'Stad',
        email : 'E-post',
        phone : 'Telefon',
        country : 'Land',
        note : 'Anteckning',
        zip : 'Postnummer',
        connect : 'Anslut',
        warehouseOverview : 'Lager Översikt',
        webshopOverview : 'Webbutik Översikt',
        update : 'Redigera',
        addWebshop : 'Lägg till webbutik',
        shopName : 'Butiksnamn',
        dropship : 'Dropship',
        shopURL : 'Butiksnamn',
        dropshipOrder : 'Dropship',
        shopEmail : 'E-post',
        apiStore : 'API nyckel',
        clientSecret : 'Klient hemlighet',
        clientID : 'Klient ID',
        storelogo : 'Logotyp',
        emailServer : 'Email server',
        emailSender : 'E-post avsändare',
        emailPassword : 'Lösenord',
        emailPort : 'Email Port',
        emailCopy : 'CC för utskick',
        emailSubject : 'Ämne',
        add : 'Lägg till',
        generalSetting : 'Globala inställningar',
        setGlobalMargin : 'Ställ in globala marginaler',
        round : 'Avrunda',
        roundUp : 'Avrunda upp',
        roundDown : 'Avrunda ner',
        saveSetting : 'Spara',
        calculateSelectItem : 'Beräkna valda artiklar',
        applyCalculateAll : 'Beräkna alla artiklar',
        exportFile : 'Exportera fil',
        show : 'Visa',
        updateWebshop : 'Uppdatera webbutiken',
        updating : 'Uppdaterar',
        save : 'Spara',
        saving : 'Uppdaterar',
        defaultVat : 'Momssats på artiklarna',
        priceListSelection : 'Prislista',
        disconnect : 'Avbryt anslutning',
        crop : 'Beskär',
        cropping : 'Beskär',
        amount : 'Belopp',
        margin : 'Marginal',
        salesPrice : 'Försäljningspris',
        date : 'Datum',
        isEqualTo : 'Är lika med',
        isGreaterThan : 'Är större än',
        isBetween : 'Är emellan',
        isLessThan : 'Är lägre än',
        received : 'Mottagen',
        processing : 'Hanteras',
        yes : 'Ja',
        no : 'Nej',
        cancel : 'Avbryt',
        clear : 'Rensa',
        done : 'Spara',
        sendEmailTrackingAlert : 'Skicka leveransbesked',
        ftpBuyerID : 'FTP ID',
        dateModified : 'Datum ändrat',
        connecting : 'Ansluter',
        addWarehouse : 'Lägg till lager',
        shopPhone : 'Telefon',
        orderRule : 'Order regler',
        cheapestProductPrice : 'Billigast pris',
        fastestDeliveryTime : 'Snabbast leverans',
        cheapestDeliveryPrice : 'Billigast fraktpris',
        brandPreference : 'Varumärke',
        orderNumber : 'Ordernummer',
        customerName : 'Kund',
        orderDate : 'Orderdatum',
        deliveryDate : 'Leveransdatum',
        supplierOrder : 'Leverantörsorder',
        enter : 'Gå till',
        webshopPricelist : 'Prislista',
        fetchingProduct : 'Hämtar produkter',
        exporting : 'exporterar',
        orderCompleted : 'Färdigbehandlad',
        orderProcessing : 'Behandlas',
        orderCancel : 'Makulerad',
        orderPending : 'Mottagen',
        picking : 'Plockas',
        inProgress : 'Behandlas',
        partialFulfilled : 'Delvis Levererad',
        failed : 'Misslyckad',  
        croppedSuccessfully : 'Beskuren',
        ascending : 'Stigande',
        descending : 'Sallande',
        logout : 'Logga ut',
        username : 'Användarnamn',
        password : 'Lösenord',
        forgetPassword : 'Glömt ditt lösenord',
        signIn : 'Logga in',
        switch : 'Växla till',
        english : 'Engelska',
        notify : 'Meddelande',
        sortBy : 'Sortera efter',
        deliveryDateAsc : 'Leveransdatum Stigande',
        deliveryDateDsc : 'Leveransdatum Fallande',
        orderDateAsc : 'Orderdatum Stigande',
        orderDateDesc : 'Orderdatum Fallande',
        amountAsc : 'Ordersumma Stigande',
        amountDesc : 'Ordersumma Fallande',
        newHere : '',
        loginFrontText : '',
        undoSelection : 'Avmarkera',
        updateAccount : 'Ladda upp profilbild',
        loginSuccessful : 'Lyckad inloggning',
        checkEmail : 'Återställningslänk mejlat',
        invalidEmail : 'Ogiltig e-mail',
        registeredSuccessful : 'Lyckad registrering',
        invalidUser : 'Ogiltig användare',
        resetSuccessful : 'Klart',
        uploadUserPhoto : 'Ladda upp Profilbild',
        croppedPhoto : 'Beskär',
        fileChosen : 'Välj fil',
        photoUploaded : 'Bild uppladdad',
        croppedSuccess : 'Klart',
        removedWarehouse : 'Lager borttagen',
        warning : 'Varning',
        deletedWarehouse : 'Lager raderad',
        deletedProductSuccess : 'Lager raderad',
        deletedOrderSuccess : 'Lager raderad',
        updatedWarehouse : 'Lager uppdaterad',
        updatedWebshop : 'Sparat',
        updateSupplier : 'Uppdatera leverantörsuppgifter',
        updateWarehouse : 'Uppdatera Lagerställen',
        currency : 'Valuta',
        sending : 'Skickar',
        creatingAccount : 'Skapar konto',
        name : 'Namn',
        costPrice : 'Inköpspris',
        salesPriceExVat : 'Pris ex moms',
        salesPriceInkVat : 'Pris ink moms',
        invalidCellValue : 'Ogiltigt värde',
        invalidMarginValue : 'Ogiltigt värde (Marginal)',
        fieldValue : 'Värdet får ej vara längre än 50 tecken',
        firstName : 'Förnamn',
        lastName : 'Efternamn',
        calculating : 'Beräknar',
        adding : 'Skapar',
        webshopOrderNumber : 'Webbutikens #',
        total : 'Totalt',
        resultFound : 'resultat',
        vendorNumber : 'Tillv art#',
        available : 'Tillgänglig',
        discontinued : 'Utgått',
        massDelete : 'Mass-Radera',
        discountedPrice : 'Kampanj',
        quantity : 'Antal',
        userManagement : 'Användarhantering',
        role : 'Roll',
        removeProducts : 'Säker på att du vill radera produkten',
        loading : 'Laddar...',
        employee : 'Anställd',
        inviteUser : 'Skicka inbjudan',
        userOverview : 'Användar Översikt',
        number : 'ID',
        shop : 'Webbutik',
        signin : 'Logging in',
        invitationSent : 'Inbjudan skickad',
        accountSettings : 'Kontoinställningar',
        account : 'Konto',
        phoneNumber : 'Telefonnummer',
        companyName : 'Företag',
        company : 'Företag',
        companyVatId : 'Orgnummer',
        billingAddress : 'Fakturaadress',
        zipCode : 'Postnummer',
        postalCode : 'Postnummer',
        shippingInfo : 'Leveransadress',
        companyEmail : 'Företag E-post',
        companyEmailNotice : 'Företag E-post',
        userUpdated : 'Kunduppgifter är uppdaterade',
        webshopStatusUpdated : 'Orderstatus är nu uppdaterad',
        deleteOrder : 'Är du säker du vill radera ordern',
        delete : 'Koppla bort',
        deleteWebshopOrder : 'Radera order',
        deleteSupplierOrder : 'Radera order',
        deleteSubscription : 'Radera Webbutik',
        deleting : '',
        notAvailable : 'Inte tillgänglig',
        yesDelete : 'Ja',
        languages : 'Språk',
        webshopInformation : 'Webshopdetaljer',
        viewDetails : 'visa detaljer',
        editInfo : 'redigera',
        viewProfile : 'Visa Profil',
        success : 'Sparat',
        price : 'Pris',
        profit : 'Vinstmarginal',
        inStock : 'lager',
        outStock : 'slut i lager',
        orderSettings : 'Order Inställningar',
        startTime : 'Start Tidpunkt',
        stopTime : 'Stop Tidpunkt',
        timezone : 'Tidszon',
        pleaseWait : 'Vänta',
        acceptingInvite : 'Accepterar inbjudan',
        invited : 'Inbjuden',
        connected : 'Ansluten',
        continue : 'Fortsätt',
        createSupplierOrder : 'Skapa leverantörsorder',
        updateSupplierOrderStatus : 'Vill du att ordern i webbutiken uppdateras med samma status',
        selectEcommerce : 'Välj e-handelsplattform',
        configureShopify : 'Konfigurera Shopify',
        specificDropshipOrder : 'Specific Order i Webshoppen',
        deliveryType : 'Leveranssätt',
        supplierHeading: 'Om flera leverantörer erbjuder har samma artikel i lager, vilken prioritetsordning ska väljas vid orderläggning?',
        brandHeading : 'Ska något specifikt varumärke bara beställas från en specifik leverantör?',
        saveMapping : 'Sparade mappningar',
        selectProduct : 'Välj minst en produkt',
        customerType : 'Leveranssätt',
        supplierSelection : 'Leverantör',
        unitPrice : 'Pris',
        totalPrice : 'Total Pris',
        brand : 'Varumärke',
        person : 'Privatperson',
        warehouseInfomation : 'Lageruppgifter',
        deleteSupplier : 'Radera leverantör',
        popularItems : 'Mest populära produkter',
        revenue : 'Omsättning',
        netProfit : 'Nettovinst',
        selectPriceList : 'Välj Prislista',
        saleStatistics : 'Sälj Statistik',
        topCountires : 'Mest sålda områden',
        created : 'Skapat',
        notConnected : 'Inte ansluten',
        activeFilter : 'Aktiva filter',
        manufacturer : 'Tillverkare',
        skuAsc : 'Sku stigande',
        skuDsc : 'SKU fallande',
        vendorNumAsc : 'Tillverkare# stigande',
        vendorNumDsc : 'Tillverkare# fallande',
        vendorNameAsc : 'Tillverkare stigande',
        vendorNameDsc : 'Tillverkare fallande',
        productNameAsc : 'Produkt stigande',
        productNameDsc : 'Produkt  fallande',
        costPriceAsc : 'Inköpspris stigande',
        costPriceDsc : 'Inköpspris fallande',
        salesPriceIncAsc : 'Utpris  stigande',
        salesPriceIncDsc : 'Utpris  fallande',
        salesPriceExAsc : 'Utpris Ex Moms stigande',
        salesPriceExDsc : 'Utpris Ex Moms fallande',
        marginAsc : 'Vinstmarginal stigande',
        marginDsc : 'Vinstmarginal fallande',
        notification : 'Meddelande',
        emailSettings : 'Email Inställningar',
        addWebshopQuickbutik : 'Anslut till Quickbutik',
        addShopifyWebshop : 'Anslut till  Shopify',
        topCustomers : 'Top Kunder',
        topProductPerformance : 'Top Produkter',
        totalSpent : 'Total Spenderat',
        totalGrossProfit : 'Total Bruttovinst',
        totalSold : 'Totalt Sålda',
        exportExcel : 'Exporters till Excel',
        supplierConnectors : 'Leverantörsintegrationer',
        turnover : 'Omsättning',
        grossProfit : 'Bruttovinst',
        thisMonth : 'Denna månad',
        thisWeek : 'Denna vecka',
        latestOrders : 'Senaste lagda ordrar',
        pleaseWaitCalculation : 'Bearbetar produktpriser, vänligen dröj',
    }
}

const i18n = createI18n({
    locale: 'se', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
})

export default i18n
