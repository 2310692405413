import moment from 'moment'
import { saveChoosenCompany, storeRuleFilter, storeSupplierFilter, storeWebshopFilter } from './../../../../services/services-jwt'
import { savePriceRule } from './../../../../services/storage-window'
import { CalculateSalesPrice, CalculateGrossMargin, CalculateSalesAndMargin } from './../../../../services/vat-calculation'


export default {
    UPDATE_LOGIN_USER (state, payload) {
        if (payload.companies.length > 0) {
            state.choosenCompany = payload.companies[0].uuid
            saveChoosenCompany(payload.companies[0].uuid)
        }
        state.loginUser = payload
    },
    UPDATE_CHOOSEN_COMPANY (state, payload) {
        state.choosenCompany = payload
        saveChoosenCompany(payload)
    },
    UPDATE_USER (state, payload) {
        state.userInfo = payload.results
    },
    UPDATE_USER_PHOTO (state, payload) {
        const userDetail = { ...state.userInfo }
        userDetail.icon = payload.icon
        state.userInfo = userDetail 
    },
    UPDATE_FILTER (state, payload) {
        state.filterRule = payload
        storeRuleFilter(payload)
    },
    UPDATE_SUPPLIER_FILTER (state, payload) {
        state.supplierFilter = payload
        storeSupplierFilter(payload)
    },
    UPDATE_WEBSHOP_FILTER (state, payload) {
        state.webshopFilter = payload
        storeWebshopFilter(payload)
    },
    REMOVE_FILTER_ITEM (state, payload) {
        const filter = state.filterRule.filter(el => el.mode !== payload.mode)
        state.filterRule = filter
        storeRuleFilter(state.filterRule)
    },
    REMOVE_WEBSHOP_FILTER_ITEM (state, payload) {
        const filter = state.webshopFilter.filter(el => el.mode !== payload.mode)
        state.webshopFilter = filter
        storeWebshopFilter(state.webshopFilter)
    },
    REMOVE_SUPPLIER_FILTER_ITEM (state, payload) {
        const filter = state.supplierFilter.filter(el => el.mode !== payload.mode)
        state.supplierFilter = filter
        storeSupplierFilter(state.supplierFilter)
    },
    UPDATE_WAREHOUSES (state, payload) {
        state.warehouses = payload
    },
    UPDATE_LINKED_USERS (state, payload) {
        state.linkedUsers = payload
    },
    UPDATE_WEBSHOP (state, payload) {
        state.webshop = payload
    },
    UPDATE_SINGLE_WEBSHOP (state, payload) {
        state.singleWebshop = payload
    },
    UPDATE_WEBSHOP_ORDERS (state, payload) {
        if (payload.data) {
            payload.data.search = payload.search || false
            payload.data.results.forEach(element => {
                element.checked = false
            })
            state.webshopOrders = JSON.parse(JSON.stringify(payload.data))
            state.copyWebshopOrders = !payload.search ? JSON.parse(JSON.stringify(payload.data)) : state.copyWebshopOrders
        } else {
            payload.search = payload.search || false
            payload.results.forEach(element => {
                element.checked = false
            })
            state.webshopOrders = JSON.parse(JSON.stringify(payload))
            state.copyWebshopOrders = !payload.search ? JSON.parse(JSON.stringify(payload)) : state.copyWebshopOrders
        }
    },
    UPDATE_PRICELIST (state, payload) {
        state.priceList = payload
    },
    UPDATE_WEBSHOP_PRODUCTS (state, payload) {
        payload.data.results.forEach(element => {
            element.checked = false
            const { priceIncVat, marginPercentage } = CalculateSalesAndMargin(
                element.supplier_product_price_stock.supplier_price, 
                element.sales_price, // Changed to sales_price, initially webshop_price
                state.priceRule.vat
            )
            element.supplier_product_price_stock.user_set_supplier_price = parseFloat(priceIncVat).toFixed() // Updating salepriceIncVat
            element.newSalesPrice = !element.sales_price ? 0 : parseFloat(element.sales_price).toFixed(2)
            element.margin = state.priceRule.round ? Math.round(marginPercentage) : parseFloat(marginPercentage).toFixed(1)
            // element.product.supplier.name = capitalizeEachString(element.product.supplier.name).toString().charAt(0).toUpperCase() + element.product.supplier.name.toString().toLowerCase().slice(1)
            element.product.supplier.name = capitalizeEachString(element.product.supplier.name)
            element.product.name = element.product.name.slice(0, 50)
        })
        payload.data.search = payload.search || false
        state.webshopProducts = JSON.parse(JSON.stringify(payload.data))
        state.copyWebshopProducts = JSON.parse(JSON.stringify(payload.data))
        state.customWebshopProducts = JSON.parse(JSON.stringify(payload.data))
        state.copyWebshopProductsSync = !payload.search ? JSON.parse(JSON.stringify(payload.data)) : state.copyWebshopProductsSync

        if (state.filterRule.length > 0) {
            this.commit('jitcontrol/MULTIPLE_ADVANCE_FILTER', state.filterRule)
        }
        
        if (state.ruleFilter !== '') {
            this.commit('jitcontrol/SORT_PRICE_RULE_TABLE', state.ruleFilter)
        }
    },
    UPDATE_RULE_FILTER_ONLY (state, payload) {
        state.ruleFilter = payload
    },
    UPDATE_POPULAR_ITEMS (state, payload) {
        state.popularItems = payload
    },
    // UPDATE_WEBSHOP_PRODUCTS_SEARCH (state, payload) {
    //     payload.results.forEach(element => {
    //         element.checked = false
    //         const { priceIncVat, marginPercentage } = CalculateSalesAndMargin(
    //             element.product_price_stock.supplier_price, 
    //             element.product_price_list.webshop_price, 
    //             state.priceRule.vat
    //         )
            
    //         element.product_price_stock.user_set_supplier_price = parseFloat(priceIncVat).toFixed() // Updating salepriceIncVat
    //         element.newSalesPrice = parseFloat(element.product_price_list.webshop_price).toFixed(2)
    //         element.margin = state.priceRule.round ? Math.round(marginPercentage) : parseFloat(marginPercentage).toFixed(1)
    //         element.supplier.name = element.supplier.name.toString().charAt(0).toUpperCase() + element.supplier.name.toString().toLowerCase().slice(1)
    //         element.product.name = element.product.name.slice(0, 70)
    //     })
    //     state.webshopProducts = JSON.parse(JSON.stringify(payload))
    //     state.copyWebshopProducts = JSON.parse(JSON.stringify(payload))
    // },
    // SEARCH_RESULTS (state, payload) {
    //     payload.results.forEach(element => {
    //         element.checked = false
    //         const { priceIncVat, marginPercentage } = CalculateSalesAndMargin(
    //             element.product_price_stock.supplier_price, 
    //             element.product_price_list.webshop_price, 
    //             state.priceRule.vat
    //         )
            
    //         element.product_price_stock.user_set_supplier_price = parseFloat(priceIncVat).toFixed() // Updating salepriceIncVat
    //         element.newSalesPrice = parseFloat(element.product_price_list.webshop_price).toFixed(2)
    //         element.margin = state.priceRule.round ? Math.round(marginPercentage) : parseFloat(marginPercentage).toFixed(1)
    //         element.supplier.name = element.supplier.name.toString().charAt(0).toUpperCase() + element.supplier.name.toString().toLowerCase().slice(1)
    //         element.product.name = element.product.name.slice(0, 70)
    //     })
    //     state.webshopProducts = JSON.parse(JSON.stringify(payload))
    // },
    UPDATE_SALESPRICE_INCLVAT_MARGIN (state, payload) {
        const ProductObject = state.webshopProducts.results[payload.id]
        const newProductIndex = state.webshopProducts.results.findIndex(el => el.uuid === ProductObject.uuid)

        const { priceIncVat, marginPercentage } = CalculateSalesAndMargin(
            parseFloat(ProductObject.supplier_product_price_stock.supplier_price),
            parseFloat(payload.salesExVat),
            state.priceRule.vat
        )
        const newProductArray = [...state.webshopProducts.results]
        
        newProductArray[newProductIndex].supplier_product_price_stock.user_set_supplier_price = state.priceRule.round ? parseFloat(priceIncVat).toFixed(2) : parseFloat(priceIncVat).toFixed(2)
        
        newProductArray[newProductIndex] = { 
            ...newProductArray[newProductIndex],
            margin : state.priceRule.round ? Math.round(marginPercentage) : parseFloat(marginPercentage).toFixed(1),
        }
        
        state.webshopProducts.results = newProductArray
        state.customWebshopProducts.results = newProductArray
    },
    UPDATE_MARGIN (state, payload) {
        const ProductObject = state.webshopProducts.results[payload.id]
        const newProductIndex = state.webshopProducts.results.findIndex(el => el.uuid === ProductObject.uuid)

        const { salesPriceExVat, marginPercentage } = CalculateGrossMargin(
            parseFloat(ProductObject.supplier_product_price_stock.supplier_price),
            parseFloat(payload.salesPrice),
            state.priceRule.vat
        )
        const newProductArray = [...state.webshopProducts.results]

        newProductArray[newProductIndex] = { 
            ...newProductArray[newProductIndex],
            margin : state.priceRule.round ? Math.round(marginPercentage) : parseFloat(marginPercentage).toFixed(1),
            newSalesPrice : state.priceRule.round ? parseFloat(salesPriceExVat).toFixed(2) : parseFloat(salesPriceExVat).toFixed(2)
        }
        state.webshopProducts.results = newProductArray
        state.customWebshopProducts.results = newProductArray
    },
    UPDATE_SALESPRICE_MARGIN (state, payload) {
        const ProductObject = state.webshopProducts.results[payload.id]
        const newProductIndex = state.webshopProducts.results.findIndex(el => el.uuid === ProductObject.uuid)

        const { salesPriceExVat, marginPercentage } = CalculateGrossMargin(
            parseFloat(payload.costPrice),
            parseFloat(ProductObject.supplier_product_price_stock.user_set_supplier_price),
            state.priceRule.vat
        )
        const newProductArray = [...state.webshopProducts.results]

        newProductArray[newProductIndex] = { 
            ...newProductArray[newProductIndex],
            margin : state.priceRule.round ? Math.round(marginPercentage) : parseFloat(marginPercentage).toFixed(1),
            newSalesPrice : state.priceRule.round ? parseFloat(salesPriceExVat).toFixed(2) : parseFloat(salesPriceExVat).toFixed(2)
        }
        state.webshopProducts.results = newProductArray
        state.customWebshopProducts.results = newProductArray
    },
    UPDATE_SALESPRICE (state, payload) { 
        const ProductObject = state.webshopProducts.results[payload.id]
        const { salesPriceExVat, salesPriceIncVat } = CalculateSalesPrice(ProductObject.supplier_product_price_stock.supplier_price, payload.margin, state.priceRule.vat)
        const newProductIndex = state.webshopProducts.results.findIndex(el => el.product.sku === ProductObject.product.sku)
        const newProductArray = [...state.webshopProducts.results]
        newProductArray[newProductIndex].supplier_product_price_stock.user_set_supplier_price = parseFloat(salesPriceIncVat).toFixed()
        newProductArray[newProductIndex] = { ...newProductArray[newProductIndex], margin : ProductObject.margin, newSalesPrice : state.priceRule.round ? parseFloat(salesPriceExVat).toFixed(2) : parseFloat(salesPriceExVat).toFixed(2) }
        state.webshopProducts.results = newProductArray
        state.customWebshopProducts.results = newProductArray
    },
    FILTER_ALL_WEBSHOP_PRODUCT (state, _) {
        state.webshopProducts = state.copyWebshopProductsSync
    },
    FILTER_WEBSHOP_PRODUCT (state, payload) {
        const filterSKUNum = state.copyWebshopProducts.results.filter(item =>
            item.product.sku.includes(`${payload.value}`)
        )
        const filterProductName = state.copyWebshopProducts.results.filter(item =>
            item.product.name.toLowerCase().includes(`${payload.value.toLowerCase()}`)
        )
        if (filterSKUNum.length > 0) {
            state.webshopProducts.results = filterSKUNum
        } else if (filterProductName.length > 0) {
            state.webshopProducts.results = filterProductName
        } else {
            state.webshopProducts.results = []
        }
    },
    UPDATE_SUPPLIERS (state, payload) {
        state.suppliers = payload
    },
    UPDATE_SUPPLIER_ORDERS (state, payload) {
        if (payload.data) {
            payload.data.search = payload.search || false
            state.supplierOrders = JSON.parse(JSON.stringify(payload.data))
            state.copySupplierOrders = !payload.search ? JSON.parse(JSON.stringify(payload.data)) : state.copySupplierOrders
        } else {
            payload.search = payload.search || false
            state.supplierOrders = JSON.parse(JSON.stringify(payload))
            state.copySupplierOrders = !payload.search ? JSON.parse(JSON.stringify(payload)) : state.copySupplierOrders
        }
    },
    UPDATE_PRICE_RULE_SET (state, payload) {
        if (payload.margin) {
            state.priceRule.margin = payload.margin
        }
        if (payload.vat) {
            state.priceRule.vat = payload.vat
        }
        if (payload.round) {
            state.priceRule.round = payload.round
        }
        savePriceRule(payload)
    },
    APPLY_ALL_CALCULATION (state, _) {
        const stateObject = state.webshopProducts.results
        stateObject.forEach(element => {
            const { salesPriceExVat, salesPriceIncVat } = CalculateSalesPrice(element.supplier_product_price_stock.supplier_price, state.priceRule.margin, state.priceRule.vat)
            element.checked = false
            element.supplier_product_price_stock.user_set_supplier_price = parseFloat(salesPriceIncVat).toFixed()
            element.newSalesPrice = state.priceRule.round ? parseFloat(salesPriceExVat).toFixed(2) : parseFloat(salesPriceExVat).toFixed(2)
            element.margin = state.priceRule.margin // || state.priceRule.vat
        })
        state.webshopProducts.results = stateObject
    },
    APPLY_SELECTED_CALCULATION (state, payload) {
        payload.row.forEach(element => {
            if (element > -1) {
                const ProductObject = state.webshopProducts.results[element]
                const { salesPriceExVat, salesPriceIncVat } = CalculateSalesPrice(ProductObject.supplier_product_price_stock.supplier_price, state.priceRule.margin, state.priceRule.vat)
                const newProductIndex = state.webshopProducts.results.findIndex(el => el.uuid === ProductObject.uuid)
                const newProductArray = [...state.webshopProducts.results]
                newProductArray[newProductIndex].supplier_product_price_stock.user_set_supplier_price = parseFloat(salesPriceIncVat).toFixed()
                newProductArray[newProductIndex] = { ...newProductArray[newProductIndex], margin : state.priceRule.margin, newSalesPrice : state.priceRule.round ? parseFloat(salesPriceExVat).toFixed(2) : parseFloat(salesPriceExVat).toFixed(2) }
                state.webshopProducts.results = newProductArray
            }
        })
    },
    UPDATE_PRICE_RULE (state, payload) {
        state.priceRule = payload

        const stateObject = state.webshopProducts.results
        stateObject.forEach(element => {
            const { salesPriceExVat, salesPriceIncVat } = CalculateSalesPrice(element.supplier_product_price_stock.supplier_price, payload.margin, payload.vat)
            element.checked = false
            element.supplier_product_price_stock.user_set_supplier_price = parseFloat(salesPriceIncVat).toFixed()
            element.newSalesPrice = state.priceRule.round ? parseFloat(salesPriceExVat).toFixed(2) : parseFloat(salesPriceExVat).toFixed(2)
            element.margin = state.priceRule.margin // || state.priceRule.vat
        })
        state.webshopProducts.results = stateObject
        savePriceRule(payload)
    },
    TOGGLE_CHECKBOX (state, payload) {
        const newOrderIndex = state.webshopOrders.results.findIndex(el => el.uuid === payload.id)
        const newOrderArray = [...state.webshopOrders.results]
        // console.log(newOrderArray)
        newOrderArray[newOrderIndex] = { ...newOrderArray[newOrderIndex], checked : !newOrderArray[newOrderIndex].checked }
        state.webshopOrders.results = newOrderArray
    },
    TOGGLE_ALL_CHECKBOX (state, payload) {
        state.webshopOrders.results.forEach(element => {
            element.checked = payload
        })
    },
    TOGGLE_WEBSHOP_PRODUCT_CHECKBOX (state, payload) {
        const newProductIndex = state.webshopProducts.results.findIndex(el => el.uuid === payload.uuid)
        const newProductArray = [...state.webshopProducts.results]
        newProductArray[newProductIndex] = { ...newProductArray[newProductIndex], checked : payload.value }
        state.webshopProducts.results = newProductArray
    },
    TOGGLE_PRODUCT_CHECKBOX (state, payload) {
        const newOrderIndex = state.webshopProducts.results.findIndex(el => el.product.sku === payload.sku)
        const newOrderArray = [...state.webshopProducts.results]
        newOrderArray[newOrderIndex] = { ...newOrderArray[newOrderIndex], checked : !newOrderArray[newOrderIndex].checked }
        state.webshopProducts.results = newOrderArray
    },
    TOGGLE_ALL_PRODUCT_CHECKBOX (state, payload) {
        state.webshopProducts.results.forEach(element => {
            element.checked = payload
        })
    },
    FILTER_ALL_WEBSHOP_ORDER (state, _) {
        state.webshopOrders.search = false
        state.webshopOrders.results = state.copyWebshopOrders.results
    },
    UPDATE_WEBSHOP_ORDER_STATUS (state, payload) {
        const filterIndex = state.copyWebshopOrders.results.findIndex(item => item.uuid === payload.order_id)
        const webshopOrderArray = [...state.webshopOrders.results]
        webshopOrderArray[filterIndex] = { 
            ...webshopOrderArray[filterIndex], 
            status : { ...webshopOrderArray[filterIndex].state, label : payload.body.status } 
        }
        state.webshopOrders.results = webshopOrderArray
    },
    FILTER_WEBSHOP_ORDER_SEARCH (state, payload) {
        const filterOrderNum = state.copyWebshopOrders.results.filter(item =>
            item.webshop_order_number.includes(`${payload.value}`)
        )

        const filterCustomer = state.copyWebshopOrders.results.filter(item => {
            if (item.shipping) {
                const name = item.shipping.first_name + ' ' + item.shipping.last_name
                // console.log(name.toLowerCase())
                return name.toLowerCase().includes(`${payload.value.toLowerCase()}`)
            }
        })

        const filterStatus = state.copyWebshopOrders.results.filter(item => 
            item.status.toLowerCase().includes(`${payload.value.toLowerCase()}`)
        )

        if (filterOrderNum.length > 0) {
            state.webshopOrders.results = filterOrderNum
        } else if (filterCustomer.length > 0) {
            state.webshopOrders.results = filterCustomer
        } else if (filterStatus.length > 0) {
            state.webshopOrders.results = filterStatus
        } else {
            state.webshopOrders.results = []
        }
    },
    FILTER_ALL_SUPPLIER_ORDER (state, _) {
        state.supplierOrders.search = false
        state.supplierOrders.results = state.copySupplierOrders.results
    },
    UPDATE_SUPPLIER_ORDER_STATUS (state, payload) {
        const filterIndex = state.copySupplierOrders.results.findIndex(item => item.uuid === payload.order_id)
        const supplierOrderArray = [...state.supplierOrders.results]
        supplierOrderArray[filterIndex] = { 
            ...supplierOrderArray[filterIndex], 
            status : {...supplierOrderArray[filterIndex].state, label : payload.body.status } 
        }
        state.supplierOrders.results = supplierOrderArray
    },
    FILTER_SUPPLIER_ORDER_SEARCH (state, payload) {
        const filterSupplier = state.copySupplierOrders.results.filter(item =>
            item.supplier.name.toLowerCase().includes(`${payload.value.toLowerCase()}`)
        )

        const filterLocation = state.copySupplierOrders.results.filter(item =>
            item.shipping.country.toLowerCase().includes(`${payload.value.toLowerCase()}`)
        ) 
        
        const filterStatus = state.copySupplierOrders.results.filter(item =>
            item.status.toLowerCase().includes(`${payload.value.toLowerCase()}`)
        )
        
        if (filterSupplier.length > 0) {
            state.supplierOrders.results = filterSupplier
        } else if (filterLocation.length > 0) {
            state.supplierOrders.results = filterLocation
        } else if (filterStatus.length > 0) {
            state.supplierOrders.results = filterStatus
        } else {
            state.supplierOrders.results = []
        }
    },
    ADVANCE_FILTER_WEBSHOP_DATE (state, payload) {
        const filterOrderDate = state.copyWebshopOrders.results.filter(item => {
            const compareDate = moment(item.date_completed.split('T')[0], ['MM-DD-YYYY', 'YYYY-MM-DD'])
            const startDate = moment(payload.date[0], ['MM-DD-YYYY', 'YYYY-MM-DD'])
            const endDate = moment(payload.date[1], ['MM-DD-YYYY', 'YYYY-MM-DD'])
            return compareDate.isBetween(startDate, endDate)
        })

        const filterDeliveryDate = state.copyWebshopOrders.results.filter(item => {
            const compareDate = moment(item.delivery_date.split('T')[0], ['MM-DD-YYYY', 'YYYY-MM-DD'])
            const startDate = moment(payload.date[0], ['MM-DD-YYYY', 'YYYY-MM-DD'])
            const endDate = moment(payload.date[1], ['MM-DD-YYYY', 'YYYY-MM-DD'])
            return compareDate.isBetween(startDate, endDate)
        })

        if (filterOrderDate.length > 0) {
            state.webshopOrders.results = filterOrderDate
        } else if (filterDeliveryDate.length > 0) {
            state.webshopOrders.results = filterOrderDate
        } else {
            state.webshopOrders.results = []
        }
    },
    ADVANCE_FILTER_WEBSHOP_STATUS (state, payload) {
        const filterStatus = state.copyWebshopOrders.results.filter(item => {
            return item.status === payload.status
        })
        if (filterStatus.length > 0) {
            state.webshopOrders.results = filterStatus
        } else {
            // console.log(filterStatus)
            state.webshopOrders.results = []
        }
    },
    ADVANCE_SORT_WEBSHOP_ORDER_NUMBER (state, payload) {
        if (state.copyWebshopOrders.results) {
            if (payload.status === 'asc') {
                const sortAsc = state.copyWebshopOrders.results.sort((a, b) => {
                    return a.webshop_order_number - b.webshop_order_number
                })
                state.webshopOrders.results = sortAsc
            } 
            if (payload.status === 'desc') {
                const sortDesc = state.copyWebshopOrders.results.sort((a, b) => {
                    return b.webshop_order_number - a.webshop_order_number
                })
                state.webshopOrders.results = sortDesc
            }
        }
    },
    ADVANCE_FILTER_AMOUNT_WEBSHOP_ORDERS (state, payload) {
        const filterAmount = state.copyWebshopOrders.results.filter(item => {
            if (item.line_items.length > 0) {
                let amount = 0
                item.line_items.forEach(element => {
                    amount += parseInt(element.price)
                })
                // console.log(amount)
                if (payload.range === 'equal') {
                    return parseFloat(amount).toFixed() === parseFloat(payload.amount).toFixed()
                }
                if (payload.range === 'between') {
                    return parseFloat(amount).toFixed() >= parseFloat(payload.start).toFixed() && parseFloat(amount).toFixed() <= parseFloat(payload.end).toFixed()
                }
                if (payload.range === 'greater') {
                    return parseFloat(amount).toFixed() > parseFloat(payload.amount).toFixed()
                }
                if (payload.range === 'less') {
                    return parseFloat(amount).toFixed() < parseFloat(payload.amount).toFixed()
                }
            }
        })

        if (filterAmount.length > 0) {
            state.webshopOrders.results = filterAmount
        } else {
            state.webshopOrders.results = []
        }
    },
    ADVANCE_FILTER_SUPPLIER_DATE (state, payload) {
        const filterOrderDate = state.copySupplierOrders.results.filter(item => {
            const compareDate = moment(item.date_posted.split('T')[0], ['MM-DD-YYYY', 'YYYY-MM-DD'])
            const startDate = moment(payload.date[0], ['MM-DD-YYYY', 'YYYY-MM-DD'])
            const endDate = moment(payload.date[1], ['MM-DD-YYYY', 'YYYY-MM-DD'])
            // console.log(startDate, ' ', endDate)
            return compareDate.isBetween(startDate, endDate)
        })

        const filterDeliveryDate = state.copySupplierOrders.results.filter(item => {
            const compareDate = moment(item.delivery_date.split('T')[0], ['MM-DD-YYYY', 'YYYY-MM-DD'])
            const startDate = moment(payload.date[0], ['MM-DD-YYYY', 'YYYY-MM-DD'])
            const endDate = moment(payload.date[1], ['MM-DD-YYYY', 'YYYY-MM-DD'])
            return compareDate.isBetween(startDate, endDate)
        })

        if (filterOrderDate.length > 0) {
            state.supplierOrders.results = filterOrderDate
        } else if (filterDeliveryDate.length > 0) {
            state.supplierOrders.results = filterOrderDate
        } else {
            state.supplierOrders.results = []
        }
    },
    ADVANCE_FILTER_SUPPLIER_STATUS (state, payload) {
        const filterStatus = state.copySupplierOrders.results.filter(item => {
            if (payload.status === 'Pending') {
                return item.status === 'Processing'
            }
        })
        if (filterStatus.length > 0) {
            state.supplierOrders.results = filterStatus
        } else {
            // console.log(filterStatus)
            state.supplierOrders.results = []
        }
    },
    ADVANCE_SORT_SUPPLIER_ORDER_NUMBER (state, payload) {
        if (state.copySupplierOrders.results) {
            if (payload.status === 'asc') {
                const sortAsc = state.copySupplierOrders.results.sort((a, b) => {
                    return a.supplier_order_number - b.supplier_order_number
                })
                state.supplierOrders.results = sortAsc
            } 
            if (payload.status === 'desc') {
                const sortDesc = state.copySupplierOrders.results.sort((a, b) => {
                    return b.supplier_order_number - a.supplier_order_number
                })
                state.supplierOrders.results = sortDesc
            }
        }
    },
    ADVANCE_FILTER_AMOUNT_SUPPLIER_ORDERS (state, payload) {
        const filterAmount = state.copySupplierOrders.results.filter(item => {
            if (item.line_items.length > 0) {
                let amount = 0
                item.line_items.forEach(element => {
                    amount += parseInt(element.price) * element.quantity
                })
                // console.log(amount)
                if (payload.range === 'equal') {
                    return parseInt(amount) === parseInt(payload.amount)
                }
                if (payload.range === 'between') {
                    return parseInt(amount) >= parseInt(payload.start) && parseInt(amount) <= parseInt(payload.end)
                }
                if (payload.range === 'greater') {
                    return parseInt(amount) > parseInt(payload.amount)
                }
                if (payload.range === 'less') {
                    return parseInt(amount) < parseInt(payload.amount)
                }
            }
        })

        if (filterAmount.length > 0) {
            state.supplierOrders.results = filterAmount
        } else {
            state.supplierOrders.results = []
        }
    },
    ADVANCE_FILTER_SUPPLIER_TYPE (state, payload) {
        const filterSupplierType = state.copySupplierOrders.results.filter(item =>
            item.supplier.name.toLowerCase().includes(`${payload.name.toLowerCase()}`)
        )
        if (filterSupplierType.length > 0) {
            state.supplierOrders.results = filterSupplierType
        } else {
            state.supplierOrders.results = []
        }
    },
    ADVANCE_FILTER_STATUS_WEBSHOP (state, payload) {
        if (state.copyWebshopProducts.results) {
            const filterStatus = state.copyWebshopProducts.results.filter(item => item.product.discontinued === payload)
            if (filterStatus.length > 0) {
                state.webshopProducts.results = filterStatus
            } else {
                state.webshopProducts.results = []
            }
        }
    },
    MULTIPLE_ADVANCE_FILTER (state, payload) {
        const productFilter = {...state.customWebshopProducts}

        const sup = payload.filter(el => el.mode === 'sales')
        if (sup.length > 0) {
            const filter = productFilter.results.filter(item => {
                if (sup[0].property.range === 'equal') {
                    return parseInt(item.supplier_product_price_stock.user_set_supplier_price) === parseInt(sup[0].property.value)
                }
                if (sup[0].property.range === 'between') {
                    return parseInt(item.supplier_product_price_stock.user_set_supplier_price) >= parseInt(sup[0].property.start) && parseInt(item.supplier_product_price_stock.user_set_supplier_price) <= parseInt(sup[0].property.end)
                }
                if (sup[0].property.range === 'greater') {
                    return parseInt(item.supplier_product_price_stock.user_set_supplier_price) > parseInt(sup[0].property.value)
                }
                if (sup[0].property.range === 'less') {
                    return parseInt(item.supplier_product_price_stock.user_set_supplier_price) < parseInt(sup[0].property.value)
                }
            })
            productFilter.results = filter
        }

        const cost = payload.filter(el => el.mode === 'cost')
        if (cost.length > 0) {
            const filterCost = productFilter.results.filter(item => {
                if (cost[0].property.range === 'equal') {
                    return parseInt(item.supplier_product_price_stock.supplier_price) === parseInt(cost[0].property.value)
                }
                if (cost[0].property.range === 'between') {
                    return parseInt(item.supplier_product_price_stock.supplier_price) >= parseInt(cost[0].property.start) && parseInt(item.supplier_product_price_stock.supplier_price) <= parseInt(cost[0].property.end)
                }
                if (cost[0].property.range === 'greater') {
                    return parseInt(item.supplier_product_price_stock.supplier_price) > parseInt(cost[0].property.value)
                }
                if (cost[0].property.range === 'less') {
                    return parseInt(item.supplier_product_price_stock.supplier_price) < parseInt(cost[0].property.value)
                }
            })
            productFilter.results = filterCost
        }

        const margin = payload.filter(el => el.mode === 'margin')
        if (margin.length > 0) {
            const filterMargin = productFilter.results.filter(item => {
                if (margin[0].property.range === 'equal') {
                    return parseInt(item.margin) === parseInt(margin[0].property.value)
                }
                if (margin[0].property.range === 'between') {
                    return parseInt(item.margin) >= parseInt(margin[0].property.start) && parseInt(item.margin) <= parseInt(margin[0].property.end)
                }
                if (margin[0].property.range === 'greater') {
                    return parseInt(item.margin) > parseInt(margin[0].property.value)
                }
                if (margin[0].property.range === 'less') {
                    return parseInt(item.margin) < parseInt(margin[0].property.value)
                }
            })
            productFilter.results = filterMargin
        }

        const amount = payload.filter(el => el.mode === 'amount')
        if (amount.length > 0) {
            if (productFilter.results) {
                if (amount[0].property.sort === 'asc') {
                    const sortAsc = productFilter.results.sort((a, b) => {
                        return parseInt(a.newSalesPrice) - parseInt(b.newSalesPrice)
                    })
    
                    state.webshopProducts.results = sortAsc
                } 
                if (amount[0].property.sort === 'desc') {
                    const sortDesc = productFilter.results.sort((a, b) => {
                        return parseInt(b.newSalesPrice) - parseInt(a.newSalesPrice)
                    })
                    state.webshopProducts.results = sortDesc
                }
            }
        }
        state.webshopProducts.results = productFilter.results
    },
    SORT_PRICE_RULE_TABLE (state, payload) {
        if (payload === 'salesAsc') {
            const sortAsc = state.customWebshopProducts.results.sort((a, b) => {
                return parseInt(a.newSalesPrice) - parseInt(b.newSalesPrice)
            })

            state.webshopProducts.results = sortAsc
        }

        if (payload === 'salesDsc') {
            const sortDsc = state.customWebshopProducts.results.sort((a, b) => {
                return parseInt(b.newSalesPrice) - parseInt(a.newSalesPrice)
            })

            state.webshopProducts.results = sortDsc
        }

        if (payload === 'marginAsc') {
            const sortAsc = state.customWebshopProducts.results.sort((a, b) => {
                return parseInt(b.margin) - parseInt(a.margin)
            })

            state.webshopProducts.results = sortAsc
        }

        if (payload === 'marginDsc') {
            console.log('land here')
            const sortDsc = state.customWebshopProducts.results.sort((a, b) => {
                return parseInt(b.margin) - parseInt(a.margin)
            })

            state.webshopProducts.results = sortDsc
        }
        state.ruleFilter = payload
    },
    ADVANCE_SORT_WEBSHOP_PRODUCT (state, payload) {
        // console.log(state.copyWebshopProducts.results)
        if (state.copyWebshopProducts.results) {
            if (payload.sort === 'asc') {
                const sortAsc = state.copyWebshopProducts.results.sort((a, b) => {
                    return parseInt(a.newSalesPrice) - parseInt(b.newSalesPrice)
                })

                state.webshopProducts.results = sortAsc
            } 
            if (payload.sort === 'desc') {
                const sortDesc = state.copyWebshopProducts.results.sort((a, b) => {
                    return parseInt(b.newSalesPrice) - parseInt(a.newSalesPrice)
                })
                state.webshopProducts.results = sortDesc
            }
        }
    },
    ADVANCE_FILTER_WEBSHOP_PRODUCT_MARGIN (state, payload) {
        const filterMargin = state.copyWebshopProducts.results.filter(item => {
            if (payload.range === 'equal') {
                return parseInt(item.margin) === parseInt(payload.value)
            }
            if (payload.range === 'between') {
                return parseInt(item.margin) >= parseInt(payload.start) && parseInt(item.margin) <= parseInt(payload.end)
            }
            if (payload.range === 'greater') {
                return parseInt(item.margin) > parseInt(payload.value)
            }
            if (payload.range === 'less') {
                return parseInt(item.margin) < parseInt(payload.value)
            }
        })

        if (filterMargin.length > 0) {
            state.webshopProducts.results = filterMargin
        } else {
            state.webshopProducts.results = []
        }
    },
    ADVANCE_FILTER_WEBSHOP_PRODUCT_SALES (state, payload) {
        const filterSales = state.copyWebshopProducts.results.filter(item => {
            if (payload.range === 'equal') {
                return parseInt(item.supplier_product_price_stock.user_set_supplier_price) === parseInt(payload.value)
            }
            if (payload.range === 'between') {
                return parseInt(item.supplier_product_price_stock.user_set_supplier_price) >= parseInt(payload.start) && parseInt(item.supplier_product_price_stock.user_set_supplier_price) <= parseInt(payload.end)
            }
            if (payload.range === 'greater') {
                return parseInt(item.supplier_product_price_stock.user_set_supplier_price) > parseInt(payload.value)
            }
            if (payload.range === 'less') {
                return parseInt(item.supplier_product_price_stock.user_set_supplier_price) < parseInt(payload.value)
            }
        })

        if (filterSales.length > 0) {
            state.webshopProducts.results = filterSales
        } else {
            state.webshopProducts.results = []
        }
    },
    ADVANCE_FILTER_WEBSHOP_PRODUCT_COST (state, payload) {
        const filterCost = state.copyWebshopProducts.results.filter(item => {
            if (payload.range === 'equal') {
                return parseInt(item.supplier_product_price_stock.supplier_price) === parseInt(payload.value)
            }
            if (payload.range === 'between') {
                return parseInt(item.supplier_product_price_stock.supplier_price) >= parseInt(payload.start) && parseInt(item.supplier_product_price_stock.supplier_price) <= parseInt(payload.end)
            }
            if (payload.range === 'greater') {
                return parseInt(item.supplier_product_price_stock.supplier_price) > parseInt(payload.value)
            }
            if (payload.range === 'less') {
                return parseInt(item.supplier_product_price_stock.supplier_price) < parseInt(payload.value)
            }
        })

        if (filterCost.length > 0) {
            state.webshopProducts.results = filterCost
        } else {
            state.webshopProducts.results = []
        }
    },
    ADVANCE_FILTER_WEBSHOP_PRODUCT_SUPPLIER_TYPE (state, payload) {
        const filterSupplyType = state.copyWebshopProducts.results.filter(item => {
           return item.supplier.name.toLowerCase().includes(`${payload.name.toLowerCase()}`)
        })
        if (filterSupplyType.length > 0) {
            state.webshopProducts.results = filterSupplyType
        } else {
            state.webshopProducts.results = []
        }
    },
    SORT_WEBSHOP_PRODUCT_SKU_ASC (state, _) {
        const sortSKU = state.copyWebshopProducts.results.sort((a, b) => {
            return a.product.sku - b.product.sku
        })
        state.webshopProducts.results = sortSKU
    },
    SORT_WEBSHOP_PRODUCT_SKU_DESC (state, _) {
        const sortSKU = state.copyWebshopProducts.results.sort((a, b) => {
            return b.product.sku - a.product.sku
        })
        state.webshopProducts.results = sortSKU
    },
    SORT_WEBSHOP_PRODUCT_NAME_ASC (state, _) {
        const sortName = state.copyWebshopProducts.results.sort((a, b) => {
            return a.product.name > b.product.name
        })
        state.webshopProducts.results = sortName
    },
    SORT_WEBSHOP_PRODUCT_NAME_DESC (state, _) {
        const sortName = state.copyWebshopProducts.results.sort((a, b) => {
            return b.product.name > a.product.name
        })
        state.webshopProducts.results = sortName
    },
    SORT_WEBSHOP_PRODUCT_QTY_ASC (state, _) {
        const sortQty = state.copyWebshopProducts.results.sort((a, b) => {
            return a.supplier_product_price_stock.supplier_quantity - b.supplier_product_price_stock.supplier_quantity
        })
        state.webshopProducts.results = sortQty
    },
    SORT_WEBSHOP_PRODUCT_QTY_DESC (state, _) {
        const sortQty = state.copyWebshopProducts.results.sort((a, b) => {
            return b.supplier_product_price_stock.supplier_quantity - a.supplier_product_price_stock.supplier_quantity
        })
        state.webshopProducts.results = sortQty
    },
    SORT_WEBSHOP_PRODUCT_COSTPRICE_ASC (state, _) {
        const sortCostPrice = state.copyWebshopProducts.results.sort((a, b) => {
            return a.supplier_product_price_stock.supplier_price - b.supplier_product_price_stock.supplier_price
        })
        state.webshopProducts.results = sortCostPrice
    },
    SORT_WEBSHOP_PRODUCT_COSTPRICE_DESC (state, _) {
        const sortCostPrice = state.copyWebshopProducts.results.sort((a, b) => {
            return b.supplier_product_price_stock.supplier_price - a.supplier_product_price_stock.supplier_price
        })
        state.webshopProducts.results = sortCostPrice
    },
    SORT_WEBSHOP_PRODUCT_SALES_ASC (state, _) {
        const sortSales = state.copyWebshopProducts.results.sort((a, b) => {
            return a.supplier_product_price_stock.supplier_price - b.supplier_product_price_stock.supplier_price
        })
        state.webshopProducts.results = sortSales
    },
    SORT_WEBSHOP_PRODUCT_SALES_DESC (state, _) {
        const sortSales = state.copyWebshopProducts.results.sort((a, b) => {
            return b.supplier_product_price_stock.supplier_price - a.supplier_product_price_stock.supplier_price
        })
        state.webshopProducts.results = sortSales
    },
    SORT_BY_SUPPLIER_ORDER (state, payload) {
        if (payload.value === 'delivery-asc') {
            const sortOrderDate = state.copySupplierOrders.results.sort((a, b) => {
                return new Date(a.delivery_date) - new Date(b.delivery_date)
            })
            if (sortOrderDate.length > 0) {
                state.supplierOrders.results = sortOrderDate
            } else {
                state.supplierOrders.results = []
            }
        }
        if (payload.value === 'delivery-dsc') {
            const sortOrderDateDesc = state.copySupplierOrders.results.sort((a, b) => {
                return new Date(b.delivery_date) - new Date(a.delivery_date)
            })
            if (sortOrderDateDesc.length > 0) {
                state.supplierOrders.results = sortOrderDateDesc
            } else {
                state.supplierOrders.results = []
            }
        }
        if (payload.value === 'order-asc') {
            const sortOrderDate = state.copySupplierOrders.results.sort((a, b) => {
                return new Date(a.date_posted) - new Date(b.date_posted)
            })
            if (sortOrderDate.length > 0) {
                state.supplierOrders.results = sortOrderDate
            } else {
                state.supplierOrders.results = []
            }
        }
        if (payload.value === 'order-desc') {
            const sortOrderDateDesc = state.copySupplierOrders.results.sort((a, b) => {
                return new Date(b.date_posted) - new Date(a.date_posted)
            })
            if (sortOrderDateDesc.length > 0) {
                state.supplierOrders.results = sortOrderDateDesc
            } else {
                state.supplierOrders.results = []
            }
        }
        if (payload.value === 'amount-asc') {
            const sortOrderAmountAsc = state.copySupplierOrders.results.sort((a, b) => {
                // let aAmount = 0
                // let bAmount = 0
                // a.line_items.forEach(element => {
                //     aAmount += parseInt(element.price) * element.quantity
                // })
                // b.line_items.forEach(element => {
                //     bAmount += parseInt(element.price) * element.quantity
                // })
                return a.items_total - b.items_total
            })
            if (sortOrderAmountAsc.length > 0) {
                state.supplierOrders.results = sortOrderAmountAsc
            } else {
                state.supplierOrders.results = []
            }
        }
        if (payload.value === 'amount-desc') {
            const sortOrderAmountDesc = state.copySupplierOrders.results.sort((a, b) => {
                // let aAmount = 0
                // let bAmount = 0
                // a.line_items.forEach(element => {
                //     aAmount += parseInt(element.price) * element.quantity
                // })
                // b.line_items.forEach(element => {
                //     bAmount += parseInt(element.price) * element.quantity
                // })
                return b.items_total - a.items_total
            })
            if (sortOrderAmountDesc.length > 0) {
                state.supplierOrders.results = sortOrderAmountDesc
            } else {
                state.supplierOrders.results = []
            }
        }
    },
    SORT_BY_WEBSHOP_ORDER (state, payload) {
        if (payload.value === 'delivery-asc') {
            const sortOrderDate = state.copyWebshopOrders.results.sort((a, b) => {
                if (a.date_completed && b.date_completed) {
                    return new Date(a.date_completed.split('T')[0]) - new Date(b.date_completed.split('T')[0])
                }
            })
            if (sortOrderDate.length > 0) {
                state.webshopOrders.results = sortOrderDate
            } else {
                state.webshopOrders.results = []
            }
        }
        if (payload.value === 'delivery-dsc') {
            const sortOrderDateDesc = state.copyWebshopOrders.results.sort((a, b) => {
                if (a.date_completed && b.date_completed) {
                    return new Date(b.date_completed.split('T')[0]) - new Date(a.date_completed.split('T')[0])
                }
            })
            if (sortOrderDateDesc.length > 0) {
                state.webshopOrders.results = sortOrderDateDesc
            } else {
                state.webshopOrders.results = []
            }
        }
        if (payload.value === 'order-asc') {
            const sortOrderDate = state.copyWebshopOrders.results.sort((a, b) => {
                if (a.created_at && b.created_at) {
                    return new Date(a.created_at.split('T')[0]) - new Date(b.created_at.split('T')[0])
                }
            })
            if (sortOrderDate.length > 0) {
                state.webshopOrders.results = sortOrderDate
            } else {
                state.webshopOrders.results = []
            }
        }
        if (payload.value === 'order-desc') {
            const sortOrderDateDesc = state.copyWebshopOrders.results.sort((a, b) => {
                if (a.created_at && b.created_at) {
                    return new Date(b.created_at.split('T')[0]) - new Date(a.created_at.split('T')[0])
                }
            })
            if (sortOrderDateDesc.length > 0) {
                state.webshopOrders.results = sortOrderDateDesc
            } else {
                state.webshopOrders.results = []
            }
        }
        if (payload.value === 'amount-asc') {
            const sortOrderAmountAsc = state.copyWebshopOrders.results.sort((a, b) => {
                // let aAmount = 0
                // let bAmount = 0
                // a.line_items.forEach(element => {
                //     aAmount += parseInt(element.price) * element.quantity
                // })
                // b.line_items.forEach(element => {
                //     bAmount += parseInt(element.price) * element.quantity
                // })
                return parseFloat(a.items_total) - parseFloat(b.items_total)
            })
            if (sortOrderAmountAsc.length > 0) {
                state.webshopOrders.results = sortOrderAmountAsc
            } else {
                state.webshopOrders.results = []
            }
        }
        if (payload.value === 'amount-desc') {
            const sortOrderAmountDesc = state.copyWebshopOrders.results.sort((a, b) => {
                // let aAmount = 0
                // let bAmount = 0
                // a.line_items.forEach(element => {
                //     aAmount += parseInt(element.price) * element.quantity
                // })
                // b.line_items.forEach(element => {
                //     bAmount += parseInt(element.price) * element.quantity
                // })
                return parseFloat(b.items_total) - parseFloat(a.items_total)
            })
            if (sortOrderAmountDesc.length > 0) {
                state.webshopOrders.results = sortOrderAmountDesc
            } else {
                state.webshopOrders.results = []
            }
        }
    },
    SORT_WEBSHOP_ORDER_NUM_ASC (state, _) {
        const sortOrderNumberAsc = state.copyWebshopOrders.results.sort((a, b) => {
            return parseInt(a.webshop_order_number) - parseInt(b.webshop_order_number)
        })
        state.webshopOrders.results = sortOrderNumberAsc
    },
    SORT_WEBSHOP_ORDER_NUM_DESC (state, _) {
        const sortOrderNumberDesc = state.copyWebshopOrders.results.sort((a, b) => {
            return parseInt(b.webshop_order_number) - parseInt(a.webshop_order_number) 
        })
        state.webshopOrders.results = sortOrderNumberDesc
    },
    SORT_ORDER_TOTAL_ASC (state, _) {
        const sortOrderTotalAsc = state.copyWebshopOrders.results.sort((a, b) => {
            return parseFloat(a.total) - parseFloat(b.total)
        })
        state.webshopOrders.results = sortOrderTotalAsc
    },
    SORT_ORDER_TOTAL_DESC (state, _) {
        const sortOrderTotalDesc = state.copyWebshopOrders.results.sort((a, b) => {
            return parseFloat(b.total) - parseFloat(a.total)
        })
        state.webshopOrders.results = sortOrderTotalDesc
    },
    SORT_WEBSHOP_ORDERS_CUS_NAME_ASC (state, _) {
        const sortCustomerNameAsc = state.copyWebshopOrders.results.sort((a, b) => {
            if (a.shipping.length > 0 &&  b.shipping.length > 0) {
                if (a.shipping[0].first_name && b.shipping[0].first_name) {
                    return a.shipping[0].first_name > b.shipping[0].first_name
                } else if (a.shipping[0].company && b.shipping[0].company) {
                    return a.shipping[0].company > b.shipping[0].company
                }
            }
        })
        state.webshopOrders.results = sortCustomerNameAsc
    },
    SORT_WEBSHOP_ORDERS_CUS_NAME_DESC (state, _) {
        const sortCustomerNameDesc = state.copyWebshopOrders.results.sort((a, b) => {
            if (a.shipping.length > 0 &&  b.shipping.length > 0) {
                if (a.shipping[0].first_name && b.shipping[0].first_name) {
                    return b.shipping[0].first_name > a.shipping[0].first_name
                } else if (a.shipping[0].company && b.shipping[0].company) {
                    return b.shipping[0].company > a.shipping[0].company
                }
            }
        })
        state.webshopOrders.results = sortCustomerNameDesc
    },
    SORT_WEBSHOP_ORDERS_STATUS_ASC (state, _) {
        const sortStatus = state.copyWebshopOrders.results.sort((a, b) => {
            return a.status > b.status
        })
        state.webshopOrders.results = sortStatus
    },
    SORT_WEBSHOP_ORDERS_STATUS_DESC (state, _) {
        const sortStatus = state.copyWebshopOrders.results.sort((a, b) => {
            return b.status > a.status
        })
        state.webshopOrders.results = sortStatus
    },
    SORT_WEBSHOP_ORDER_CREATED_DATE_ASC (state, _) {
        const sortDate = state.copyWebshopOrders.results.sort((a, b) => {
            return new Date(a.created_at) - new Date(b.created_at)
        })
        state.webshopOrders.results = sortDate
    },
    SORT_WEBSHOP_ORDER_TOTAL_ASC (state, _) {
        const sortTotal = state.copyWebshopOrders.results.sort((a, b) => {
            return parseFloat(a.total) - parseFloat(b.total)
        })
        state.webshopOrders.results = sortTotal
    },
    SORT_WEBSHOP_ORDER_TOTAL_DESC (state, _) {
        const sortTotal = state.copyWebshopOrders.results.sort((a, b) => {
            return parseFloat(b.total) - parseFloat(a.total)
        })
        state.webshopOrders.results = sortTotal
    },
    SORT_WEBSHOP_ORDER_DELIVERY_DATE_ASC (state, _) {
        const sortDate = state.copyWebshopOrders.results.sort((a, b) => {
            return new Date(a.date_completed) - new Date(b.date_completed)
        })
        state.webshopOrders.results = sortDate
    },
    SORT_WEBSHOP_ORDER_DELIVERY_DATE_DESC (state, _) {
        const sortDate = state.copyWebshopOrders.results.sort((a, b) => {
            return new Date(b.date_completed) - new Date(a.date_completed)
        })
        state.webshopOrders.results = sortDate
    },
    SORT_WEBSHOP_ORDER_CREATED_DATE_DESC (state, _) {
        const sortDate = state.copyWebshopOrders.results.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at)
        })
        state.webshopOrders.results = sortDate
    },
    SORT_SUPPLIER_ORDER_REF_ASC (state, _) {
        const sortRef = state.copySupplierOrders.results.sort((a, b) => {
            return parseInt(a.supplier_order_number) - parseInt(b.supplier_order_number)
        })
        state.supplierOrders.results = sortRef
    },
    SORT_SUPPLIER_ORDER_REF_DESC (state, _) {
        const sortRef = state.copySupplierOrders.results.sort((a, b) => {
            return parseInt(b.supplier_order_number) - parseInt(a.supplier_order_number)
        })
        state.supplierOrders.results = sortRef
    },
    SORT_SUPPLIER_ORDER_NAME_ASC (state, _) {
        const sortName = state.copySupplierOrders.results.sort((a, b) => {
            return a.supplier.name > b.supplier.name
        })
        state.supplierOrders.results = sortName
    },
    SORT_SUPPLIER_ORDER_NAME_DESC (state, _) {
        const sortName = state.copySupplierOrders.results.sort((a, b) => {
            return b.supplier.name > a.supplier.name
        })
        state.supplierOrders.results = sortName
    },
    SORT_SUPPLIER_WEBSHOP_NUMBER_ASC (state, _) {
        const sortWebshopNumber = state.copySupplierOrders.results.sort((a, b) => {
            return parseInt(a.supplier_order_number) - parseInt(b.supplier_order_number)
        })
        state.supplierOrders.results = sortWebshopNumber
    },
    SORT_SUPPLIER_WEBSHOP_NUMBER_DESC (state, _) {
        const sortRef = state.copySupplierOrders.results.sort((a, b) => {
            return parseInt(b.supplier_order_number) - parseInt(a.supplier_order_number)
        })
        state.supplierOrders.results = sortRef
    },
    SORT_SUPPLIER_ORDER_LOCATION_ASC (state, _) {
        const sortLocation = state.copySupplierOrders.results.sort((a, b) => {
            if (a.shipping && b.shipping) {
                return a.shipping.country > b.shipping.country
            }
        })
        state.supplierOrders.results = sortLocation
    },
    SORT_SUPPLIER_ORDER_LOCATION_DESC (state, _) {
        const sortLocation = state.copySupplierOrders.results.sort((a, b) => {
            if (a.shipping && b.shipping) {
                return b.shipping.country > a.shipping.country
            }
        })
        state.supplierOrders.results = sortLocation
    },
    SORT_SUPPLIER_ORDER_TOTAL_ASC (state, _) {
        const sortTotal = state.copySupplierOrders.results.sort((a, b) => {
            return a.items_total - b.items_total	
        })
        state.supplierOrders.results = sortTotal
    },
    SORT_SUPPLIER_ORDER_TOTAL_DESC (state, _) {
        const sortTotal = state.copySupplierOrders.results.sort((a, b) => {
            return b.items_total - a.items_total	
        })
        state.supplierOrders.results = sortTotal
    },
    SORT_SUPPLIER_ORDER_STATUS_ASC (state, _) {
        const sortStatus = state.copySupplierOrders.results.sort((a, b) => {
            return a.status > b.status
        })
        state.supplierOrders.results = sortStatus
    },
    SORT_SUPPLIER_ORDER_STATUS_DESC (state, _) {
        const sortStatus = state.copySupplierOrders.results.sort((a, b) => {
            return b.status > a.status
        })
        state.supplierOrders.results = sortStatus
    },
    SORT_SUPPLIER_ORDER_EXPECTED_DATE_ASC (state, _) {
        const sortDate = state.copySupplierOrders.results.sort((a, b) => {
            return new Date(a.delivery_date) - new Date(b.delivery_date)
        })
        state.supplierOrders.results = sortDate
    },
    SORT_SUPPLIER_ORDER_EXPECTED_DATE_DSC (state, _) {
        const sortDate = state.copySupplierOrders.results.sort((a, b) => {
            return new Date(b.delivery_date) - new Date(a.delivery_date)
        })
        state.supplierOrders.results = sortDate
    },
    SORT_SUPPLIER_ORDER_POSTED_DATE_ASC (state, _) {
        const sortDate = state.copySupplierOrders.results.sort((a, b) => {
            return new Date(a.date_posted) - new Date(b.date_posted)
        })
        state.supplierOrders.results = sortDate
    },
    SORT_SUPPLIER_ORDER_POSTED_DATE_DESC (state, _) {
        const sortDate = state.copySupplierOrders.results.sort((a, b) => {
            return new Date(b.date_posted) - new Date(a.date_posted)
        })
        state.supplierOrders.results = sortDate
    },
}


function capitalizeEachString (name) {
    return name !== undefined ? name.split(' ').map(v => typeof v !== 'string' ? v + ' ' : v.charAt(0).toUpperCase() + v.toLowerCase().slice(1) + ' ').join('').trim() : name
}