<template>
    <router-view></router-view>
</template>

<script>
import { getLocale, saveLocale } from '@/services/storage-window'
export default {
    name: 'App',
    mounted () {
        if (!getLocale()) {
            saveLocale('se')
        } else {
            this.$i18n.locale = getLocale()
        }
    }
}
</script>

<style lang='scss'>
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
html, body {
    font-family: 'Roboto', sans-serif !important;
}
.checkbox {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  flex-shrink: 0;
  height: 1em;
  width: 1em;
  color: #181C4C;
  background-color: #fff;
  border-color: #DFE0EB;
  border-width: 1px;
  border-radius: 0.25rem;
}

.checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

@media not print {
  .checkbox::-ms-check {
    border-width: 1px;
    color: transparent;
    background: inherit;
    border-color: inherit;
    border-radius: inherit;
  }
}

.checkbox:focus {
  outline: none;
  /* box-shadow: 0 0 0 3px #181C4C; */
  border-color: #788181;
}
.tabledata {
    .handsontable .make-me-red{
        color: #e62968 !important;
        font-weight: bold;
    }
    .handsontable .make-me-blue{
        color: #092C5C !important;
        font-weight: bold;
    }
    .handsontable .colored {
		background: #FCB515 !important;
		color: #222;
		animation: blinker 1s linear;
	}
    @keyframes blinker {
        50% {
            opacity: 0.5;
        }
    }
}
#mapping-scroll::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

#mapping-scroll::-webkit-scrollbar-thumb {
    background: transparent;
}

#mapping-scroll::-webkit-scrollbar-track {
    background: #ddd;
}

#mapping-scroll{
    scrollbar-width: thin; 
}
</style>
