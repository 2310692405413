import { getPriceRule } from './../../../../services/storage-window'
import { getChoosenCompany, getRuleFilterStorage, getSupplierFilterStorage, getWebshopFilterStorage } from './../../../../services/services-jwt/'

export default {
    loginUser : {},
    choosenCompany : getChoosenCompany() || null,
    filterRule : getRuleFilterStorage() || [],
    supplierFilter : getSupplierFilterStorage() || null,
    webshopFilter : getWebshopFilterStorage() || null,
    userInfo : {},
    warehouses : [],
    linkedUsers : [],
    webshop : [],
    singleWebshop : {},
    webshopOrders : {
        results : []
    },
    copyWebshopOrders : [],
    webshopProducts : {
        results : []
    },
    copyWebshopProducts : [],
    copyWebshopProductsSync : [],
    customWebshopProducts : [],
    supplierOrders : {
        results : []
    },
    copySupplierOrders : [],
    priceList : [],
    priceRule : getPriceRule() || {},
    suppliers : [],
    popularItems : [],
    ruleFilter : '',
}
